import React, { useCallback, useEffect, useState } from "react";

import { useMutation } from "@apollo/client";

import { Button, ButtonColorEnum, ButtonSizeEnum } from "@/components/common/button";
import Confetti from "@/components/common/confetti";
import ExpertCard from "@/components/common/expertCard";
import { FeedbackSlider } from "@/components/common/feedbackSlider";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import Rating from "@/components/common/rating";
import { convertToDateLiterary, convertToDurabilityLiterary, convertToTime } from "@/utils/dateUtils";

import { setReview, setReviewVariables } from "../graphql/__generated__/setReview";
import { SET_REVIEW_MUTATION } from "../graphql/SET_REVIEW_MUTATION";

import { SessionProps } from "./types";
import "./styles.scss";

const SessionCard = ({ session }: SessionProps) => {
  const [isReviewed, setIsReviewed] = useState<boolean>(!!session.review);
  const [showAnimationWithConfetti, setShowAnimationWithConfetti] = useState(false);
  const [rating, setRating] = useState<number | null>(session.review?.rating || null);
  const [isReviewSectionOpen, setIsReviewSectionOpen] = useState<boolean>(false);
  const [clickedStar, setClickedStar] = useState<number | null>();
  const [text, setText] = useState<string>("");

  const [setReviewMutation, {
    data: setReviewData,
    loading: setReviewLoading,
    error: setReviewError,
  }] = useMutation<setReview, setReviewVariables>(SET_REVIEW_MUTATION);

  const handleStarClick = useCallback((clickedRating: number) => {
    setIsReviewSectionOpen(true);
    setClickedStar(clickedRating);
    setReviewMutation({ variables: { rating: clickedRating, sessionID: session.id } });
  }, [session.id, setReviewMutation]);

  const handleReviewSubmit = () => {
    if (!clickedStar) {
      return;
    }
    setIsReviewed(true);
    setReviewMutation({ variables: { text, rating: clickedStar, sessionID: session.id } });
    setShowAnimationWithConfetti(true);
  };

  useEffect(() => {
    if (setReviewData && !setReviewLoading && !setReviewError) {
      if (clickedStar) {
        setRating(clickedStar);
      }
    }
  }, [setReviewData, setReviewLoading, setReviewError]);

  return (
    <>
      {showAnimationWithConfetti && <Confetti />}
      <div className="session-card">
        <div className="session-card__top">
          <div className="session-card__time">
            Консультация
            {" "}
            {convertToDateLiterary(session.startTimestamp)}
            {" "}
            в&nbsp;
            {convertToTime(session.startTimestamp)}
          </div>
          <div className="session-card__duration">
            <Icon type={IconTypeEnum.Time} size={IconSizeEnum.Size20} />
            {convertToDurabilityLiterary(session.startTimestamp, session.endTimestamp)}
          </div>
        </div>
        <div className="session-card__expert">
          <ExpertCard expert={session.expert} />
        </div>
        <div className="session-card__bottom">
          {!isReviewed && (
          <>
            <h3>Оцените консультацию</h3>
            <div className="session-card__stars">
              <FeedbackSlider rating={clickedStar} onChange={handleStarClick} disabled={setReviewLoading} />
            </div>
            <div className={isReviewSectionOpen ? "session-card__review opened" : "session-card__review"}>
              <div className="session-card__subtext">Спасибо за вашу оценку</div>
              <div className="session-card__label">Ваш комментарий</div>
              <div className="session-card__comment">
                <textarea
                  placeholder="Что вам понравилось или не понравилось?"
                  value={text}
                  onChange={(e) =>
                    setText(e.target.value)}
                />
              </div>
              <div className="session-card__button-container">
                <Button
                  text="Оставить отзыв"
                  color={ButtonColorEnum.Dark}
                  size={ButtonSizeEnum.Middle}
                  className="session-card__button"
                  onClick={handleReviewSubmit}
                  isLoading={setReviewLoading}
                />
              </div>
            </div>
          </>
          )}
          {isReviewed && (
          <div className="session-card__rating">
            <span className="session-card__rating-text">Ваша оценка</span>
            <Rating value={rating ?? 5} />
          </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SessionCard;
