import * as React from "react";

import ProfileLayout from "@/components/profile/profileLayout";
import Sessions from "@/components/profile/sessions";
import { isBrowser } from "@/utils/env";

import "@/pages/common.scss";

const SessionsPage = () => {
  if (!isBrowser()) {
    return null;
  }

  return (
    <ProfileLayout>
      <Sessions />
    </ProfileLayout>
  );
};

export default SessionsPage;
