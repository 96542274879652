import React, { useContext, useEffect, useState } from "react";

import { useLazyQuery, useQuery } from "@apollo/client";
import { navigate } from "gatsby";

import { OrderEnum, SortByEnum } from "@/autoGeneratedGlobalTypes";
import { Button, ButtonColorEnum, ButtonSizeEnum } from "@/components/common/button";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import Loader from "@/components/common/loader";
import GlobalContext from "@/contexts/Global/GlobalContext";
import UserContext from "@/contexts/User/UserContext";
import { isBrowser } from "@/utils/env";

import { SESSIONS_PAGE_SIZE } from "./constants";
import { getMoreSessions, getMoreSessionsVariables } from "./graphql/__generated__/getMoreSessions";
import {
  getSessionsPageData,
  getSessionsPageDataVariables,
  getSessionsPageData_getSessions,
} from "./graphql/__generated__/getSessionsPageData";
import { GET_MORE_SESSIONS } from "./graphql/GET_MORE_SESSIONS";
import { GET_SESSIONS_PAGE_DATA } from "./graphql/GET_SESSIONS_PAGE_DATA";
import SessionCard from "./sessionCard";
import "./styles.scss";

const Sessions = () => {
  const { isUserLoggedIn } = useContext(UserContext);
  const { isExpert } = useContext(GlobalContext);

  const [sessions, setSessions] = useState<getSessionsPageData_getSessions>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const {
    data,
    error,
    loading,
  } = useQuery<getSessionsPageData, getSessionsPageDataVariables>(GET_SESSIONS_PAGE_DATA, {
    variables: {
      sessionCount: SESSIONS_PAGE_SIZE,
      filter: { minDurationInMinutes: 1 }, // needed because there are short trashy sessions we don't show
      sorting: {
        sortBy: SortByEnum.id,
        order: OrderEnum.desc,
      },
    },
  });

  useEffect(() => {
    if (data && !error && !loading) {
      setSessions(data.getSessions);
    }
  }, [data, error, loading]);

  const [loadMore, {
    data: loadMoreData,
    error: loadMoreError,
    loading: loadMoreLoading,
  }] = useLazyQuery<
    getMoreSessions,
    getMoreSessionsVariables
  >(GET_MORE_SESSIONS);

  const onLoadMoreClick = () => {
    loadMore({
      variables: {
        first: SESSIONS_PAGE_SIZE,
        after: sessions?.pageInfo.endCursor,
        filter: { minDurationInMinutes: 1 }, // needed because there are short trashy sessions we don't show
        sorting: {
          sortBy: SortByEnum.id,
          order: OrderEnum.desc,
        },
      },
    });
  };

  useEffect(() => {
    if (loadMoreData && !loadMoreError && !loadMoreLoading) {
      setCurrentPage((oldVal: number) =>
        oldVal + 1);
      setSessions({
        ...loadMoreData.getSessions,
        edges: sessions ? sessions.edges.concat(loadMoreData.getSessions.edges) : loadMoreData.getSessions.edges,
        pageInfo: loadMoreData.getSessions.pageInfo,
      });
    }
  }, [loadMoreData, loadMoreError, loadMoreLoading, sessions]);

  useEffect(() => {
    if (!isUserLoggedIn || isExpert) {
      if (isBrowser()) {
        navigate("/", { replace: true });
      }
    }
  }, [isExpert, isUserLoggedIn]);

  if (!data && loading) {
    return (
      <div className="sessions">
        <h1 className="sessions__header">Мои консультации</h1>
        {/* todo: replace with skeleton */}
        <Loader />
      </div>
    );
  }

  if (!sessions || sessions.edges.length === 0) {
    return (
      <div className="sessions">
        <h1 className="sessions__header">Мои консультации</h1>
        <div className="sessions__empty">
          <Icon type={IconTypeEnum.Search} size={IconSizeEnum.Size32} />
          <div className="empty__text">Консультаций пока не было</div>
        </div>
      </div>
    );
  }

  const remainingCount = (sessions.totalCount ?? 0) > currentPage * SESSIONS_PAGE_SIZE
    ? (sessions.totalCount ?? 0) - currentPage * SESSIONS_PAGE_SIZE
    : 0;

  return (
    <div className="sessions">
      <h1 className="sessions__header">Мои консультации</h1>
      {sessions
        && sessions.edges.length > 0
        && sessions.edges.map((session) =>
          <SessionCard session={session.node} key={session.node.id} />)}
      {sessions?.pageInfo.hasNextPage && (
        <Button
          text={`Показать ещё ${Math.min(remainingCount, SESSIONS_PAGE_SIZE)}`}
          size={ButtonSizeEnum.Middle}
          color={ButtonColorEnum.White}
          onClick={onLoadMoreClick}
          disabled={loadMoreLoading}
          className="sessions__button"
        />
      )}
    </div>
  );
};

export default Sessions;
