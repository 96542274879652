import { gql } from "@apollo/client";

export const GET_SESSIONS_PAGE_DATA = gql`
  query getSessionsPageData($sessionCount: Int, $filter: SessionFilter, $sorting: SortParameters) {
    getSessions(first: $sessionCount, filter: $filter, sorting: $sorting) {
      edges {
        node {
          id
          status
          expert {
            id
            name
            description
            mobileImage: image(size: 105) {
              onex
              twox
              threex
            }
            desktopImage: image(size: 176) {
              onex
              twox
              threex
            }
            rates {
              videoCall
              phoneCall
              chat
            }
            gender
            rating
            skills {
              badge
            }
            title
            state
          }
          review {
            id
            text
            rating
            authorUser {
              id
              name
            }
          }
          startTimestamp
          endTimestamp
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;
