import { gql } from "@apollo/client";

export const GET_MORE_SESSIONS = gql`
  query getMoreSessions($first: Int, $after: String, $filter: SessionFilter, $sorting: SortParameters) {
    getSessions(first: $first, after: $after, filter: $filter, sorting: $sorting) {
      edges {
        node {
          id
          status
          expert {
            id
            name
            description
            mobileImage: image(size: 105) {
              onex
              twox
              threex
            }
            desktopImage: image(size: 176) {
              onex
              twox
              threex
            }
            rates {
              videoCall
              phoneCall
              chat
            }
            gender
            rating
            skills {
              badge
            }
            title
            state
          }
          review {
            id
            text
            rating
            authorUser {
              id
              name
            }
          }
          startTimestamp
          endTimestamp
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;
